import { ChangeSetType, CosmicDocumentStatus, DocumentReleaseStatus, DocumentType, DrDocumentClosureRationale, EztDocumentType, FracasAnalysisStatus, FracasCauseOfFailure, FracasFailureEnvironment, FracasFailureMode, FracasFailurePhase, FracasFailureType, FracasScoring, FracasSubSystem, RcaRootCauseCategory, TpsDocumentType } from '~/db_types';
import { DeliveryOrderLineItemStatus, DeliveryOrderLineItemType } from '~/db_types/swagger_types';
export var CosmicManagedDocumentType;
(function (CosmicManagedDocumentType) {
    CosmicManagedDocumentType["C_DISCREPANCY_REPORT"] = "C_DISCREPANCY_REPORT";
    CosmicManagedDocumentType["C_EASY_TRANSFER"] = "C_EASY_TRANSFER";
    CosmicManagedDocumentType["C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM"] = "C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM";
    CosmicManagedDocumentType["C_RCA"] = "C_RCA";
    CosmicManagedDocumentType["C_TPS"] = "C_TPS";
    CosmicManagedDocumentType["C_WORKFLOW"] = "C_WORKFLOW";
})(CosmicManagedDocumentType || (CosmicManagedDocumentType = {}));
export var TrackingDocumentType;
(function (TrackingDocumentType) {
    TrackingDocumentType["ACCEPTANCE_DATA_PACK"] = "ACCEPTANCE_DATA_PACK";
    TrackingDocumentType["BARCODE_DECLARATION"] = "BARCODE_DECLARATION";
    TrackingDocumentType["CONFIGURATION_CHANGE_ORDER"] = "CONFIGURATION_CHANGE_ORDER";
    TrackingDocumentType["CR"] = "CR";
    TrackingDocumentType["DISCREPANCY_REPORT"] = "DISCREPANCY_REPORT";
    TrackingDocumentType["DRAWING"] = "DRAWING";
    TrackingDocumentType["EASY_TRANSFER"] = "EASY_TRANSFER";
    TrackingDocumentType["ELABEL"] = "ELABEL";
    TrackingDocumentType["ELROD"] = "ELROD";
    TrackingDocumentType["ENGINEERING_CHANGE"] = "ENGINEERING_CHANGE";
    TrackingDocumentType["FAILURE_INVESTIGATION_ANOMALY_REPORT"] = "FAILURE_INVESTIGATION_ANOMALY_REPORT";
    TrackingDocumentType["GCAR"] = "GCAR";
    TrackingDocumentType["HAZARD_RESPONSE_LEVEL"] = "HAZARD_RESPONSE_LEVEL";
    TrackingDocumentType["IRREGULAR_PARTS_AUTHORIZATION_REQUEST"] = "IRREGULAR_PARTS_AUTHORIZATION_REQUEST";
    TrackingDocumentType["ITEM_FOR_INVESTIGATION"] = "ITEM_FOR_INVESTIGATION";
    TrackingDocumentType["MATERIAL_USAGE_AGREEMENT"] = "MATERIAL_USAGE_AGREEMENT";
    TrackingDocumentType["NASA_SPACE_PART_AUTHORIZATION_REQUEST"] = "NASA_SPACE_PART_AUTHORIZATION_REQUEST";
    TrackingDocumentType["NINE_ZERO_SIX"] = "NINE_ZERO_SIX";
    TrackingDocumentType["OPS_NOM_DECLARATION"] = "OPS_NOM_DECLARATION";
    TrackingDocumentType["OTHER_DOCUMENT"] = "OTHER_DOCUMENT";
    TrackingDocumentType["OVER_DUE_TAG"] = "OVER_DUE_TAG";
    TrackingDocumentType["PROBLEM_REPORTING_AND_CORRECTIVE_ACTION"] = "PROBLEM_REPORTING_AND_CORRECTIVE_ACTION";
    TrackingDocumentType["PROCEDURE"] = "PROCEDURE";
    TrackingDocumentType["RSA"] = "RSA";
    TrackingDocumentType["TPS"] = "TPS";
    TrackingDocumentType["VEHICLE_CERTIFICATION"] = "VEHICLE_CERTIFICATION";
    TrackingDocumentType["WAIVER"] = "WAIVER";
    TrackingDocumentType["YELLOW_TAG_NOTICE"] = "YELLOW_TAG_NOTICE";
})(TrackingDocumentType || (TrackingDocumentType = {}));
export const CosmicManagedDocumentTypeDisplay = new Map([
    [CosmicManagedDocumentType.C_DISCREPANCY_REPORT, 'cDR'],
    [CosmicManagedDocumentType.C_EASY_TRANSFER, 'cEZT'],
    [CosmicManagedDocumentType.C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM, 'cFRACAS'],
    [CosmicManagedDocumentType.C_RCA, 'cRCA'],
    [CosmicManagedDocumentType.C_TPS, 'cTPS'],
    [CosmicManagedDocumentType.C_WORKFLOW, 'cWORKFLOW']
]);
// used to test whether a given ChangeSetType is one used in exclusively in cDocs. example:
// cDocsChangeSetTypes.includes(someCsType)
export const cDocsChangeSetTypes = [
    ChangeSetType.C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM_LINE_ITEM,
    ChangeSetType.C_TPS_LINE_ITEM,
    ChangeSetType.C_DISCREPANCY_REPORT_LINE_ITEM,
    ChangeSetType.C_EASY_TRANSFER_LINE_ITEM,
    ChangeSetType.COSMIC_DOCUMENT_INITIALIZATION,
    ChangeSetType.WORK_ORDER_STEP,
    ChangeSetType.C_WORKFLOW_LINE_ITEM,
    ChangeSetType.C_RCA_LINE_ITEM
];
export const CosmicDocumentStatusDisplay = new Map([
    [CosmicDocumentStatus.CLOSED, 'Closed'],
    [CosmicDocumentStatus.DRAFT, 'Draft'],
    [CosmicDocumentStatus.OPEN, 'Open'],
    [CosmicDocumentStatus.PENDING, 'Pending'],
    [CosmicDocumentStatus.REJECTED, 'Rejected'],
    [CosmicDocumentStatus.WORK_ORDER_STEP_REJECTED, 'Step rejected'],
    [CosmicDocumentStatus.WORK_ORDER_STEPS_COMPLETE, 'Steps complete']
]);
export const CosmicDocumentStatusTooltipDisplay = new Map([
    [
        CosmicDocumentStatus.CLOSED,
        'Document has been initialized. All steps/tasks complete and no other open line items exist on this Document.'
    ],
    [CosmicDocumentStatus.DRAFT, 'Document has been created but is not initialized.'],
    [CosmicDocumentStatus.OPEN, 'Document has been initialized. Document has at least 1 open step/task.'],
    [CosmicDocumentStatus.PENDING, 'Document has an OPEN initialization.'],
    [CosmicDocumentStatus.REJECTED, 'Document has a REJECTED initialization.'],
    [CosmicDocumentStatus.WORK_ORDER_STEP_REJECTED, 'Document has been initialized. At least 1 step rejected.'],
    [CosmicDocumentStatus.WORK_ORDER_STEPS_COMPLETE, 'Document has been initialized. All steps completed.']
]);
export const DocumentTypeDisplay = new Map([
    [DocumentType.ACCEPTANCE_DATA_PACK, 'Acceptance Data Pack'],
    [DocumentType.BARCODE_DECLARATION, 'Barcode Declaration'],
    [DocumentType.C_DISCREPANCY_REPORT, 'cDR'],
    [DocumentType.C_EASY_TRANSFER, 'cEZT'],
    [DocumentType.C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM, 'cFRACAS'],
    [DocumentType.C_RCA, 'cRCA'],
    [DocumentType.C_DIGITAL_RECORD_REVIEW_ITEM, 'cRID'],
    [DocumentType.C_TPS, 'cTPS'],
    [DocumentType.C_WORKFLOW, 'cWorkflow'],
    [DocumentType.CONFIGURATION_CHANGE_ORDER, 'CCO'],
    [DocumentType.CR, 'CR'],
    [DocumentType.DISCREPANCY_REPORT, 'DR'],
    [DocumentType.DELIVERY_ORDER, 'DO'],
    [DocumentType.DRAWING, 'Drawing'],
    [DocumentType.EASY_TRANSFER, 'EZT'],
    [DocumentType.ELABEL, 'e-LABEL'],
    [DocumentType.ELROD, 'e-LROD'],
    [DocumentType.ENGINEERING_CHANGE, 'EC'],
    [DocumentType.FAILURE_INVESTIGATION_ANOMALY_REPORT, 'FIAR'],
    [DocumentType.GCAR, 'GCAR'],
    [DocumentType.HAZARD_RESPONSE_LEVEL, 'HRL'],
    [DocumentType.IRREGULAR_PARTS_AUTHORIZATION_REQUEST, 'IPAR'],
    [DocumentType.ITEM_FOR_INVESTIGATION, 'IFI'],
    [DocumentType.MATERIAL_USAGE_AGREEMENT, 'MUA'],
    [DocumentType.NASA_SPACE_PART_AUTHORIZATION_REQUEST, 'NSPAR'],
    [DocumentType.NINE_ZERO_SIX, '906'],
    [DocumentType.OPS_NOM_DECLARATION, 'OPS NOM Declaration'],
    [DocumentType.OTHER_DOCUMENT, 'OD (Other Document)'],
    [DocumentType.OVER_DUE_TAG, 'ODT'],
    [DocumentType.PROBLEM_REPORTING_AND_CORRECTIVE_ACTION, 'PRACA'],
    [DocumentType.PROCEDURE, 'PROC'],
    [DocumentType.RSA, 'RSA'],
    [DocumentType.TPS, 'TPS'],
    [DocumentType.VEHICLE_CERTIFICATION, 'Vehicle Certification'],
    [DocumentType.WAIVER, 'WAIVER'],
    [DocumentType.YELLOW_TAG_NOTICE, 'YTN']
]);
export var DocumentLineItemType;
(function (DocumentLineItemType) {
    DocumentLineItemType["CONFIGURATION_CHANGE_ORDER"] = "CONFIGURATION_CHANGE_ORDER";
    DocumentLineItemType["DISCREPANCY_REPORT"] = "DISCREPANCY_REPORT";
    DocumentLineItemType["EASY_TRANSFER"] = "EASY_TRANSFER";
    DocumentLineItemType["ENGINEERING_CHANGE"] = "ENGINEERING_CHANGE";
    DocumentLineItemType["FAILURE_INVESTIGATION_ANOMALY_REPORT"] = "FAILURE_INVESTIGATION_ANOMALY_REPORT";
    DocumentLineItemType["IRREGULAR_PARTS_AUTHORIZATION_REQUEST"] = "IRREGULAR_PARTS_AUTHORIZATION_REQUEST";
    DocumentLineItemType["ITEM_FOR_INVESTIGATION"] = "ITEM_FOR_INVESTIGATION";
    DocumentLineItemType["MATERIAL_USAGE_AGREEMENT"] = "MATERIAL_USAGE_AGREEMENT";
    DocumentLineItemType["NASA_SPACE_PART_AUTHORIZATION_REQUEST"] = "NASA_SPACE_PART_AUTHORIZATION_REQUEST";
    DocumentLineItemType["OTHER_DOCUMENT"] = "OTHER_DOCUMENT";
    DocumentLineItemType["OVER_DUE_TAG"] = "OVER_DUE_TAG";
    DocumentLineItemType["PROBLEM_REPORTING_AND_CORRECTIVE_ACTION"] = "PROBLEM_REPORTING_AND_CORRECTIVE_ACTION";
    DocumentLineItemType["PROCEDURE"] = "PROCEDURE";
    DocumentLineItemType["TPS"] = "TPS";
    DocumentLineItemType["WAIVER"] = "WAIVER";
    DocumentLineItemType["YELLOW_TAG_NOTICE"] = "YELLOW_TAG_NOTICE";
})(DocumentLineItemType || (DocumentLineItemType = {}));
export var DocumentTypesWithoutLineItems;
(function (DocumentTypesWithoutLineItems) {
    DocumentTypesWithoutLineItems["DRAWING"] = "DRAWING";
    DocumentTypesWithoutLineItems["PROCEDURE"] = "PROCEDURE";
})(DocumentTypesWithoutLineItems || (DocumentTypesWithoutLineItems = {}));
export const DocumentLineItemTypeDisplay = new Map([
    [DocumentLineItemType.CONFIGURATION_CHANGE_ORDER, 'CCO'],
    [DocumentLineItemType.DISCREPANCY_REPORT, 'DR'],
    [DocumentLineItemType.EASY_TRANSFER, 'EZT'],
    [DocumentLineItemType.ENGINEERING_CHANGE, 'EC'],
    [DocumentLineItemType.FAILURE_INVESTIGATION_ANOMALY_REPORT, 'FIAR'],
    [DocumentLineItemType.IRREGULAR_PARTS_AUTHORIZATION_REQUEST, 'IPAR'],
    [DocumentLineItemType.ITEM_FOR_INVESTIGATION, 'IFI'],
    [DocumentLineItemType.MATERIAL_USAGE_AGREEMENT, 'MUA'],
    [DocumentLineItemType.NASA_SPACE_PART_AUTHORIZATION_REQUEST, 'NSPAR'],
    [DocumentLineItemType.OTHER_DOCUMENT, 'OD'],
    [DocumentLineItemType.OVER_DUE_TAG, 'ODT'],
    [DocumentLineItemType.PROBLEM_REPORTING_AND_CORRECTIVE_ACTION, 'PRACA'],
    [DocumentLineItemType.PROCEDURE, 'PROC'],
    [DocumentLineItemType.TPS, 'TPS'],
    [DocumentLineItemType.WAIVER, 'WAIVER'],
    [DocumentLineItemType.YELLOW_TAG_NOTICE, 'YTN']
]);
export const DocumentTypesThatUseInitialization = [
    DocumentType.C_DISCREPANCY_REPORT,
    DocumentType.C_EASY_TRANSFER,
    DocumentType.C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM,
    DocumentType.C_RCA,
    DocumentType.C_WORKFLOW,
    DocumentType.C_DIGITAL_RECORD_REVIEW_ITEM
];
export const DefaultDocumentAuthorityOverride = new Map([
    [DocumentType.C_DISCREPANCY_REPORT, 'cDR_BUYOFF'],
    [DocumentType.C_TPS, 'cTPS_BUYOFF']
]);
export const DeliveryOrderLineItemStatusDisplay = new Map([
    [DeliveryOrderLineItemStatus.IN_PROCESS, 'In process'],
    [DeliveryOrderLineItemStatus.NOT_STARTED, 'Not started'],
    [DeliveryOrderLineItemStatus.PROBABLE, 'Probable'],
    [DeliveryOrderLineItemStatus.PROPOSAL, 'Proposal'],
    [DeliveryOrderLineItemStatus.VOID, 'Void'],
    [DeliveryOrderLineItemStatus.READY_FOR_COMPLETION, 'Ready for completion']
]);
export const DeliveryOrderLineItemTypeDisplay = new Map([
    [DeliveryOrderLineItemType.FL, 'FL'],
    [DeliveryOrderLineItemType.NEW_BUILD, 'New build'],
    [DeliveryOrderLineItemType.PROCURE, 'Procure'],
    [DeliveryOrderLineItemType.REFURB, 'Refurb']
]);
export var DocumentProcedureType;
(function (DocumentProcedureType) {
    DocumentProcedureType["ADV_EMU"] = "ADV_EMU";
    DocumentProcedureType["CIII"] = "CIII";
    DocumentProcedureType["CTSD"] = "CTSD";
    DocumentProcedureType["CTSDSH1263"] = "CTSDSH1263";
    DocumentProcedureType["CTSDSS1545"] = "CTSDSS1545";
    DocumentProcedureType["CTSDSS1637"] = "CTSDSS1637";
    DocumentProcedureType["GSE"] = "GSE";
    DocumentProcedureType["I"] = "I";
    DocumentProcedureType["II"] = "II";
    DocumentProcedureType["JSC"] = "JSC";
    DocumentProcedureType["LSS"] = "LSS";
    DocumentProcedureType["ORLAN"] = "ORLAN";
    DocumentProcedureType["RUS"] = "RUS";
    DocumentProcedureType["SP"] = "SP";
    DocumentProcedureType["SSA"] = "SSA";
    DocumentProcedureType["TBD"] = "TBD";
    DocumentProcedureType["WETF"] = "WETF";
})(DocumentProcedureType || (DocumentProcedureType = {}));
export const DocumentProcedureTypeDisplay = new Map([
    [DocumentProcedureType.ADV_EMU, 'ADV EMU'],
    [DocumentProcedureType.CIII, 'CIII'],
    [DocumentProcedureType.CTSD, 'CTSD'],
    [DocumentProcedureType.CTSDSH1263, 'CTSDSH1263'],
    [DocumentProcedureType.CTSDSS1545, 'CTSDSS1545'],
    [DocumentProcedureType.CTSDSS1637, 'CTSDSS1637'],
    [DocumentProcedureType.GSE, 'GSE'],
    [DocumentProcedureType.I, 'I'],
    [DocumentProcedureType.II, 'II'],
    [DocumentProcedureType.JSC, 'JSC'],
    [DocumentProcedureType.LSS, 'LSS'],
    [DocumentProcedureType.ORLAN, 'ORLAN'],
    [DocumentProcedureType.RUS, 'RUS'],
    [DocumentProcedureType.SP, 'SP'],
    [DocumentProcedureType.SSA, 'SSA'],
    [DocumentProcedureType.TBD, 'TBD'],
    [DocumentProcedureType.WETF, 'WETF']
]);
export const DocumentReleaseStatusDisplay = new Map([
    [DocumentReleaseStatus.AVAILABLE, 'Available'],
    [DocumentReleaseStatus.CANCELLED, 'Cancelled'],
    [DocumentReleaseStatus.IN_WORK, 'In Work'],
    [DocumentReleaseStatus.INACTIVE, 'Inactive'],
    [DocumentReleaseStatus.OBSOLETE, 'Obsolete'],
    [DocumentReleaseStatus.RELEASED, 'Released'],
    [DocumentReleaseStatus.UNKNOWN, 'Unknown']
]);
export const DocumentLineItemTypeToChangeSetType = new Map([
    [DocumentLineItemType.CONFIGURATION_CHANGE_ORDER, ChangeSetType.CONFIGURATION_CHANGE_ORDER_LINE_ITEM],
    [DocumentLineItemType.DISCREPANCY_REPORT, ChangeSetType.DISCREPANCY_REPORT_LINE_ITEM],
    [DocumentLineItemType.EASY_TRANSFER, ChangeSetType.EASY_TRANSFER_LINE_ITEM],
    [DocumentLineItemType.ENGINEERING_CHANGE, ChangeSetType.ENGINEERING_CHANGE_LINE_ITEM],
    [
        DocumentLineItemType.FAILURE_INVESTIGATION_ANOMALY_REPORT,
        ChangeSetType.FAILURE_INVESTIGATION_ANOMALY_REPORT_LINE_ITEM
    ],
    [
        DocumentLineItemType.IRREGULAR_PARTS_AUTHORIZATION_REQUEST,
        ChangeSetType.IRREGULAR_PARTS_AUTHORIZATION_REQUEST_LINE_ITEM
    ],
    [DocumentLineItemType.ITEM_FOR_INVESTIGATION, ChangeSetType.ITEM_FOR_INVESTIGATION_LINE_ITEM],
    [DocumentLineItemType.MATERIAL_USAGE_AGREEMENT, ChangeSetType.MATERIAL_USAGE_AGREEMENT_LINE_ITEM],
    [
        DocumentLineItemType.NASA_SPACE_PART_AUTHORIZATION_REQUEST,
        ChangeSetType.NASA_SPACE_PART_AUTHORIZATION_REQUEST_LINE_ITEM
    ],
    [DocumentLineItemType.OTHER_DOCUMENT, ChangeSetType.OTHER_DOCUMENT_LINE_ITEM],
    [DocumentLineItemType.OVER_DUE_TAG, ChangeSetType.OVER_DUE_TAG_LINE_ITEM],
    [
        DocumentLineItemType.PROBLEM_REPORTING_AND_CORRECTIVE_ACTION,
        ChangeSetType.PROBLEM_REPORTING_AND_CORRECTIVE_ACTION_LINE_ITEM
    ],
    [DocumentLineItemType.PROCEDURE, ChangeSetType.PROCEDURE_LINE_ITEM],
    [DocumentLineItemType.TPS, ChangeSetType.TPS_LINE_ITEM],
    [DocumentLineItemType.WAIVER, ChangeSetType.WAIVER_LINE_ITEM],
    [DocumentLineItemType.YELLOW_TAG_NOTICE, ChangeSetType.YELLOW_TAG_NOTICE_LINE_ITEM]
]);
export const DrDocumentClosureRationaleDisplay = new Map([
    [DrDocumentClosureRationale.MAJOR_MRB, 'Major MRB'],
    [DrDocumentClosureRationale.MINOR_MRB, 'Minor MRB'],
    [DrDocumentClosureRationale.NON_MRB, 'Non MRB'],
    [DrDocumentClosureRationale.PMRB, 'PMRB'],
    [DrDocumentClosureRationale.UNDETERMINED, 'Undetermined'],
    [DrDocumentClosureRationale.WRITTEN_IN_ERROR_NOT_VALID, 'Written In Error / Not Valid']
]);
export const EztDocumentTypeDisplay = new Map([
    [EztDocumentType.ISSUE, 'Issue'],
    [EztDocumentType.RECEIPT, 'Receipt'],
    [EztDocumentType.TRANSFER_ISSUED, 'Transfer as issued']
]);
export const FracasCauseOfFailureDisplay = new Map([
    [FracasCauseOfFailure.UNKNOWN, 'Unknown']
]);
export const FracasFailureModeDisplay = new Map([[FracasFailureMode.UNKNOWN, 'Unknown']]);
export const FracasFailureTypeDisplay = new Map([
    [FracasFailureType.ANOMALY, 'Anomaly'],
    [FracasFailureType.DISCREPANCY, 'Discrepancy'],
    [FracasFailureType.FAILURE, 'Failure'],
    [FracasFailureType.NON_CRITICAL, 'Non-Critical']
]);
export const FracasScoringDisplay = new Map([
    [FracasScoring.ESSENTIAL_FUNCTIONMISSION_CRITICAL, 'Essential function / Mission critical'],
    [FracasScoring.NON_ESSENTIAL_FUNCTION, 'Non-Essential function']
]);
export const FracasSubSystemDisplay = new Map([
    [FracasSubSystem.SOFTWARE, 'Software'],
    [FracasSubSystem.X_INFO, 'xINFO'],
    [FracasSubSystem.X_PGS, 'xPGS'],
    [FracasSubSystem.X_PLSS, 'xPLSS']
]);
export const FracasAnalysisStatusDisplay = new Map([
    [FracasAnalysisStatus.COMPLETE, 'Complete'],
    [FracasAnalysisStatus.IN_WORK, 'In-Work'],
    [FracasAnalysisStatus.NOT_STARTED, 'Not Started']
]);
export const FracasFailurePhaseDisplay = new Map([
    [FracasFailurePhase.INSPECTION, 'Inspection'],
    [FracasFailurePhase.ON_ORBIT, 'On-Orbit'],
    [FracasFailurePhase.TESTING, 'Testing']
]);
export const FracasFailureEnvironmentDisplay = new Map([
    [FracasFailureEnvironment.ACCEPTANCE_TESTING, 'Acceptance Testing'],
    [FracasFailureEnvironment.DVT_TESTING, 'DVT Testing'],
    [FracasFailureEnvironment.GATEWAY, 'Gateway'],
    [FracasFailureEnvironment.HLS, 'HLS'],
    [FracasFailureEnvironment.ISS, 'ISS'],
    [FracasFailureEnvironment.LUNAR, 'Lunar'],
    [FracasFailureEnvironment.QUALITY_TESTING, 'Quality Testing']
]);
export const TpsDocumentTypeDisplay = new Map([
    [TpsDocumentType.CONFIGURATION_CHANGE, 'Configuration Change'],
    [TpsDocumentType.FABRICATION, 'Fabrication'],
    [TpsDocumentType.ASSEMBLY, 'Assembly'],
    [TpsDocumentType.TEMPORARY_CONFIGURATION_CHANGE, 'Temporary Configuration Change'],
    [TpsDocumentType.DOWNGRADE_NON_DISCREPANT, 'Downgrade Non-Discrepant'],
    [TpsDocumentType.CLASSIFICATION, 'Classification'],
    [TpsDocumentType.ACTIVATION_INACTIVATION_OF_HARDWARE, 'Activation/Inactivation of Hardware'],
    [TpsDocumentType.OTHER_CONFIGURATION_CHANGE, 'Other Configuration Change'],
    [TpsDocumentType.MAINTENANCE, 'Maintenance'],
    [TpsDocumentType.ACCEPTANCE_TEST, 'Acceptance Test'],
    [TpsDocumentType.QUALIFICATION_CERTIFICATION_TEST, 'Qualification Certificate Test'],
    [TpsDocumentType.TEST_CALIBRATION, 'Test Calibration'],
    [TpsDocumentType.ENGINEERING_EVALUATION, 'Engineering Evaluation'],
    [TpsDocumentType.TRANSFER_ROUND_ROBIN, 'Transfer Round Robin'],
    [TpsDocumentType.OTHER_NON_CONFIGURATION_CHANGE, 'Other Non-Configuration Change'],
    [TpsDocumentType.POST_FLIGHT_ACTIVITY, 'Post-Flight Activity'],
    [TpsDocumentType.FABRICATION_I_E, 'Fabrication IE'],
    [TpsDocumentType.I_E_PROJECT_WORK, 'IE Project Work'] // sp??
]);
export var RcaAwardFeePeriod;
(function (RcaAwardFeePeriod) {
    RcaAwardFeePeriod["AFP_2_WEAKNESS"] = "AFP_2_WEAKNESS";
    RcaAwardFeePeriod["AFP_3_FIRST_QTR_AOI"] = "AFP_3_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_3_MIDTERM"] = "AFP_3_MIDTERM";
    RcaAwardFeePeriod["AFP_3_THIRD_QTR_AOI"] = "AFP_3_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_3_WEAKNESS"] = "AFP_3_WEAKNESS";
    RcaAwardFeePeriod["AFP_4_FIRST_QTR_AOI"] = "AFP_4_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_4_MIDTERM"] = "AFP_4_MIDTERM";
    RcaAwardFeePeriod["AFP_4_THIRD_QTR_AOI"] = "AFP_4_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_4_WEAKNESS"] = "AFP_4_WEAKNESS";
    RcaAwardFeePeriod["AFP_5_MIDTERM"] = "AFP_5_MIDTERM";
    RcaAwardFeePeriod["AFP_5_THIRD_QTR_AOI"] = "AFP_5_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_5_WEAKNESS"] = "AFP_5_WEAKNESS";
    RcaAwardFeePeriod["AFP_5_FIRST_QTR_AOI"] = "AFP_5_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_6_WEAKNESS"] = "AFP_6_WEAKNESS";
    RcaAwardFeePeriod["AFP_6_MIDTERM"] = "AFP_6_MIDTERM";
    RcaAwardFeePeriod["AFP_6_THIRD_QTR_AOI"] = "AFP_6_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_6_FIRST_QTR_AOI"] = "AFP_6_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_7_WEAKNESS"] = "AFP_7_WEAKNESS";
    RcaAwardFeePeriod["AFP_7_FIRST_QTR_AOI"] = "AFP_7_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_7_MIDTERM"] = "AFP_7_MIDTERM";
    RcaAwardFeePeriod["AFP_7_THIRD_QTR_AOI"] = "AFP_7_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_8_WEAKNESS"] = "AFP_8_WEAKNESS";
    RcaAwardFeePeriod["AFP_8_FIRST_QTR_AOI"] = "AFP_8_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_8_MIDTERM"] = "AFP_8_MIDTERM";
    RcaAwardFeePeriod["AFP_8_THIRD_QTR_AOI"] = "AFP_8_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_9_WEAKNESS"] = "AFP_9_WEAKNESS";
    RcaAwardFeePeriod["AFP_9_FIRST_QTR_AOI"] = "AFP_9_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_9_MIDTERM"] = "AFP_9_MIDTERM";
    RcaAwardFeePeriod["AFP_9_THIRD_QTR_AOI"] = "AFP_9_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_10_WEAKNESS"] = "AFP_10_WEAKNESS";
    RcaAwardFeePeriod["AFP_10_FIRST_QTR_AOI"] = "AFP_10_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_10_MIDTERM"] = "AFP_10_MIDTERM";
    RcaAwardFeePeriod["AFP_10_THIRD_QTR_AOI"] = "AFP_10_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_11_WEAKNESS"] = "AFP_11_WEAKNESS";
    RcaAwardFeePeriod["AFP_11_FIRST_QTR_AOI"] = "AFP_11_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_11_MIDTERM"] = "AFP_11_MIDTERM";
    RcaAwardFeePeriod["AFP_11_THIRD_QTR_AOI"] = "AFP_11_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_12_WEAKNESS"] = "AFP_12_WEAKNESS";
    RcaAwardFeePeriod["AFP_12_FIRST_QTR_AOI"] = "AFP_12_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_12_MIDTERM"] = "AFP_12_MIDTERM";
    RcaAwardFeePeriod["AFP_12_THIRD_QTR_AOI"] = "AFP_12_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_13_FIRST_QTR_AOI"] = "AFP_13_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_13_WEAKNESS"] = "AFP_13_WEAKNESS";
    RcaAwardFeePeriod["AFP_13_MIDTERM"] = "AFP_13_MIDTERM";
    RcaAwardFeePeriod["AFP_13_THIRD_QTR_AOI"] = "AFP_13_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_14_WEAKNESS"] = "AFP_14_WEAKNESS";
    RcaAwardFeePeriod["AFP_14_FIRST_QTR_AOI"] = "AFP_14_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_14_MIDTERM"] = "AFP_14_MIDTERM";
    RcaAwardFeePeriod["AFP_14_THIRD_QTR_AOI"] = "AFP_14_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_15_WEAKNESS"] = "AFP_15_WEAKNESS";
    RcaAwardFeePeriod["AFP_15_FIRST_QTR_AOI"] = "AFP_15_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_15_MIDTERM"] = "AFP_15_MIDTERM";
    RcaAwardFeePeriod["AFP_15_THIRD_QTR_AOI"] = "AFP_15_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_16_WEAKNESS"] = "AFP_16_WEAKNESS";
    RcaAwardFeePeriod["AFP_16_FIRST_QTR_AOI"] = "AFP_16_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_16_MIDTERM"] = "AFP_16_MIDTERM";
    RcaAwardFeePeriod["AFP_16_THIRD_QTR_AOI"] = "AFP_16_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_17_WEAKNESS"] = "AFP_17_WEAKNESS";
    RcaAwardFeePeriod["AFP_17_FIRST_QTR_AOI"] = "AFP_17_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_17_MIDTERM"] = "AFP_17_MIDTERM";
    RcaAwardFeePeriod["AFP_17_THIRD_QTR_AOI"] = "AFP_17_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_18_WEAKNESS"] = "AFP_18_WEAKNESS";
    RcaAwardFeePeriod["AFP_18_FIRST_QTR_AOI"] = "AFP_18_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_18_MIDTERM"] = "AFP_18_MIDTERM";
    RcaAwardFeePeriod["AFP_18_THIRD_QTR_AOI"] = "AFP_18_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_19_WEAKNESS"] = "AFP_19_WEAKNESS";
    RcaAwardFeePeriod["AFP_19_FIRST_QTR_AOI"] = "AFP_19_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_19_MIDTERM"] = "AFP_19_MIDTERM";
    RcaAwardFeePeriod["AFP_19_THIRD_QTR_AOI"] = "AFP_19_THIRD_QTR_AOI";
    RcaAwardFeePeriod["AFP_20_WEAKNESS"] = "AFP_20_WEAKNESS";
    RcaAwardFeePeriod["AFP_20_FIRST_QTR_AOI"] = "AFP_20_FIRST_QTR_AOI";
    RcaAwardFeePeriod["AFP_20_MIDTERM"] = "AFP_20_MIDTERM";
    RcaAwardFeePeriod["AFP_20_THIRD_QTR_AOI"] = "AFP_20_THIRD_QTR_AOI";
})(RcaAwardFeePeriod || (RcaAwardFeePeriod = {}));
export const RcaAwardFeePeriodDisplay = new Map([
    [RcaAwardFeePeriod.AFP_2_WEAKNESS, 'AFP 2 Weakness'],
    [RcaAwardFeePeriod.AFP_3_FIRST_QTR_AOI, 'AFP 3 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_3_MIDTERM, 'AFP 3 Midterm'],
    [RcaAwardFeePeriod.AFP_3_THIRD_QTR_AOI, 'AFP 3 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_3_WEAKNESS, 'AFP 3 Weakness'],
    [RcaAwardFeePeriod.AFP_4_FIRST_QTR_AOI, 'AFP 4 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_4_MIDTERM, 'AFP 4 Midterm'],
    [RcaAwardFeePeriod.AFP_4_THIRD_QTR_AOI, 'AFP 4 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_4_WEAKNESS, 'AFP 4 Weakness'],
    [RcaAwardFeePeriod.AFP_5_FIRST_QTR_AOI, 'AFP 5 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_5_MIDTERM, 'AFP 5 Midterm'],
    [RcaAwardFeePeriod.AFP_5_THIRD_QTR_AOI, 'AFP 5 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_5_WEAKNESS, 'AFP 5 Weakness'],
    [RcaAwardFeePeriod.AFP_6_FIRST_QTR_AOI, 'AFP 6 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_6_MIDTERM, 'AFP 6 Midterm'],
    [RcaAwardFeePeriod.AFP_6_THIRD_QTR_AOI, 'AFP 6 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_6_WEAKNESS, 'AFP 6 Weakness'],
    [RcaAwardFeePeriod.AFP_7_FIRST_QTR_AOI, 'AFP 7 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_7_MIDTERM, 'AFP 7 Midterm'],
    [RcaAwardFeePeriod.AFP_7_THIRD_QTR_AOI, 'AFP 7 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_7_WEAKNESS, 'AFP 7 Weakness'],
    [RcaAwardFeePeriod.AFP_8_FIRST_QTR_AOI, 'AFP 8 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_8_MIDTERM, 'AFP 8 Midterm'],
    [RcaAwardFeePeriod.AFP_8_THIRD_QTR_AOI, 'AFP 8 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_8_WEAKNESS, 'AFP 8 Weakness'],
    [RcaAwardFeePeriod.AFP_9_FIRST_QTR_AOI, 'AFP 9 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_9_MIDTERM, 'AFP 9 Midterm'],
    [RcaAwardFeePeriod.AFP_9_THIRD_QTR_AOI, 'AFP 9 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_9_WEAKNESS, 'AFP 9 Weakness'],
    [RcaAwardFeePeriod.AFP_10_FIRST_QTR_AOI, 'AFP 10 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_10_MIDTERM, 'AFP 10 Midterm'],
    [RcaAwardFeePeriod.AFP_10_THIRD_QTR_AOI, 'AFP 10 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_10_WEAKNESS, 'AFP 10 Weakness'],
    [RcaAwardFeePeriod.AFP_11_FIRST_QTR_AOI, 'AFP 11 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_11_MIDTERM, 'AFP 11 Midterm'],
    [RcaAwardFeePeriod.AFP_11_THIRD_QTR_AOI, 'AFP 11 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_11_WEAKNESS, 'AFP 11 Weakness'],
    [RcaAwardFeePeriod.AFP_12_FIRST_QTR_AOI, 'AFP 12 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_12_MIDTERM, 'AFP 12 Midterm'],
    [RcaAwardFeePeriod.AFP_12_THIRD_QTR_AOI, 'AFP 12 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_12_WEAKNESS, 'AFP 12 Weakness'],
    [RcaAwardFeePeriod.AFP_13_FIRST_QTR_AOI, 'AFP 13 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_13_MIDTERM, 'AFP 13 Midterm'],
    [RcaAwardFeePeriod.AFP_13_THIRD_QTR_AOI, 'AFP 13 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_13_WEAKNESS, 'AFP 13 Weakness'],
    [RcaAwardFeePeriod.AFP_14_FIRST_QTR_AOI, 'AFP 14 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_14_MIDTERM, 'AFP 14 Midterm'],
    [RcaAwardFeePeriod.AFP_14_THIRD_QTR_AOI, 'AFP 14 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_14_WEAKNESS, 'AFP 14 Weakness'],
    [RcaAwardFeePeriod.AFP_15_FIRST_QTR_AOI, 'AFP 15 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_15_MIDTERM, 'AFP 15 Midterm'],
    [RcaAwardFeePeriod.AFP_15_THIRD_QTR_AOI, 'AFP 15 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_15_WEAKNESS, 'AFP 15 Weakness'],
    [RcaAwardFeePeriod.AFP_16_FIRST_QTR_AOI, 'AFP 16 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_16_MIDTERM, 'AFP 16 Midterm'],
    [RcaAwardFeePeriod.AFP_16_THIRD_QTR_AOI, 'AFP 16 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_16_WEAKNESS, 'AFP 16 Weakness'],
    [RcaAwardFeePeriod.AFP_17_FIRST_QTR_AOI, 'AFP 17 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_17_MIDTERM, 'AFP 17 Midterm'],
    [RcaAwardFeePeriod.AFP_17_THIRD_QTR_AOI, 'AFP 17 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_17_WEAKNESS, 'AFP 17 Weakness'],
    [RcaAwardFeePeriod.AFP_18_FIRST_QTR_AOI, 'AFP 18 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_18_MIDTERM, 'AFP 18 Midterm'],
    [RcaAwardFeePeriod.AFP_18_THIRD_QTR_AOI, 'AFP 18 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_18_WEAKNESS, 'AFP 18 Weakness'],
    [RcaAwardFeePeriod.AFP_19_FIRST_QTR_AOI, 'AFP 19 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_19_MIDTERM, 'AFP 19 Midterm'],
    [RcaAwardFeePeriod.AFP_19_THIRD_QTR_AOI, 'AFP 19 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_19_WEAKNESS, 'AFP 19 Weakness'],
    [RcaAwardFeePeriod.AFP_20_FIRST_QTR_AOI, 'AFP 20 First QTR AOI'],
    [RcaAwardFeePeriod.AFP_20_MIDTERM, 'AFP 20 Midterm'],
    [RcaAwardFeePeriod.AFP_20_THIRD_QTR_AOI, 'AFP 20 Third QTR AOI'],
    [RcaAwardFeePeriod.AFP_20_WEAKNESS, 'AFP 20 Weakness']
]);
export const RcaRootCauseCategoryDisplay = new Map([
    [
        RcaRootCauseCategory.CUSTOMER_INITIATED_SCHEDULE_PRESSURE_ADDITIONAL_SCOPE,
        'Customer initiated schedule pressure additional scope'
    ],
    [RcaRootCauseCategory.ERROR_IN_PROCEDURE, 'Error in procedure'],
    [RcaRootCauseCategory.HUMAN_ERROR, 'Human error'],
    [
        RcaRootCauseCategory.LACK_OF_A_DEFINED_SCOPE_OR_POOR_UNDERSTANDING_OF_SCOPE,
        'Lack of a defined scope or poor understanding of scope'
    ],
    [RcaRootCauseCategory.LACK_OF_DOCUMENTED_PROCEDURE, 'Lack of documented procedure'],
    [RcaRootCauseCategory.LACK_OF_MAINTENANCE_UPKEEP, 'Lack of maintenance upkeep'],
    [RcaRootCauseCategory.LACK_OF_TRAINING_OR_MENTORSHIP, 'Lack of training or mentorship'],
    [RcaRootCauseCategory.PERSONAL_WARRANTY_VIOLATION, 'Personal warranty violation'],
    [RcaRootCauseCategory.POOR_ASSEMBLY_TEST_OF_PRODUCT, 'Poor assembly test of product'],
    [RcaRootCauseCategory.POOR_COMMUNICATION_AMONG_STAKEHOLDERS_TEAM, 'Poor communication among stakeholders team'],
    [RcaRootCauseCategory.POOR_ENGINEERING_OR_DESIGN, 'Poor engineering or design'],
    [RcaRootCauseCategory.POOR_INSPECTION_OF_FINAL_PRODUCT, 'Poor inspection of final product'],
    [RcaRootCauseCategory.POOR_PROJECT_MANAGEMENT, 'Poor project management']
]);
