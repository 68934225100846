import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AssemblyTemplateType, Side } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { EntityType, SideDisplay } from '~/nasa_ui/types';
import { AssemblyTemplateTypeDisplay } from '~/nasa_ui/types/enums/assembly';
import { isNullOrUndefined, postAssemblyTemplate, putPartReference } from '~/nasa_ui/utils';
import { PartReferenceTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformPartReferenceResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalAssemblyTemplateCreate = class ModalAssemblyTemplateCreate extends Mixins(BaseModal) {
    parentData = null;
    PartReferenceTableHeaders = PartReferenceTableHeaders;
    selectedPartReference = null;
    selectedPartReferenceEdited = null;
    selectedPartReferenceRequest = null;
    showPartReferenceSelector = false;
    Side = Side;
    SideDisplay = SideDisplay;
    transformedPartReferences = [];
    formData = {
        attributes: {},
        autoHardwareListId: null,
        documentId: null,
        installedOn: Side.NONE,
        maxQuantity: 1,
        minQuantity: 1,
        name: null,
        pbsItemId: null,
        sequence: null,
        subType: null
    };
    parentAssemblyTemplateId;
    get createAssemblyTemplateRequest() {
        if (!this.computedAssemblyTemplateType ||
            isNullOrUndefined(this.formData.minQuantity) ||
            isNullOrUndefined(this.formData.maxQuantity) ||
            !this.formData.sequence ||
            !this.formData.installedOn) {
            return null;
        }
        const parts = [...this.computedAssemblyTemplateParts].map((part) => {
            if (part.createPartReference) {
                Object.keys(part.createPartReference).forEach((key) => {
                    if (part.createPartReference && key.startsWith('_')) {
                        delete part.createPartReference[key];
                    }
                });
            }
            return part;
        });
        // TODO: Support auto hardware list
        return {
            attributes: this.formData.attributes,
            installedOn: this.formData.installedOn,
            maxQuantity: this.formData.maxQuantity,
            minQuantity: this.formData.minQuantity,
            name: this.formData.name,
            parentId: this.parentAssemblyTemplateId,
            parts,
            pbsItemId: this.formData.pbsItemId,
            sequence: this.formData.sequence,
            subType: this.computedAssemblyTemplateType
        };
    }
    get computedAssemblyTemplateParts() {
        if (!this.transformedPartReferences) {
            return [];
        }
        return this.transformedPartReferences.map((partReference) => {
            const request = { attributes: {} };
            if (partReference.id) {
                request.partReferenceId = partReference.id;
            }
            else {
                request.createPartReference = {
                    ...partReference
                };
            }
            return request;
        });
    }
    get computedAssemblyTemplateType() {
        if (!this.parentData) {
            return null;
        }
        // Otherwise the parent subType will be the child subType
        return this.parentData.subType;
    }
    get computedColor() {
        if (this.isParentGenericAssemblyTemplate) {
            return EntityType.ASSEMBLY_TEMPLATE;
        }
        else if (this.isParentEventAssemblyTemplate) {
            return EntityType.EVENT;
        }
        else if (this.isParentPbsAssemblyTemplate) {
            return EntityType.PRODUCT_BREAKDOWN_STRUCTURE;
        }
        return EntityType.ASSEMBLY_TEMPLATE;
    }
    get installedOnMessage() {
        switch (this.formData.installedOn) {
            case Side.LEFT:
                return 'Left';
            case Side.RIGHT:
                return 'Right';
            case Side.NONE:
                return 'Not applicable';
        }
    }
    get isDuplicateBySequenceByParent() {
        if (isNullOrUndefined(this.formData.sequence) || !this.parentData?.children?.length) {
            return false;
        }
        return this.parentData.children.some((assyTemp) => assyTemp.sequence === this.formData.sequence && assyTemp.depth === 1);
    }
    get isFormValid() {
        return this.isQuantityValid && this.isSequenceValid;
    }
    get isParentEventAssemblyTemplate() {
        return this.parentData?.subType === AssemblyTemplateType.EVENT_ASSEMBLY;
    }
    get isParentGenericAssemblyTemplate() {
        return this.parentData?.subType === AssemblyTemplateType.ASSEMBLY_TEMPLATE;
    }
    get isParentPbsAssemblyTemplate() {
        return this.parentData?.subType === AssemblyTemplateType.PRODUCT_BREAKDOWN_STRUCTURE;
    }
    get isQuantityValid() {
        return this.isNotMoreThanMaxQuantity(this.formData.minQuantity, this.formData.maxQuantity);
    }
    get isRoot() {
        return !this.parentAssemblyTemplateId;
    }
    get isSequenceValid() {
        return (Boolean(this.formData.sequence) &&
            !this.isDuplicateBySequenceByParent &&
            this.sequenceIsWithinSmallIntRange &&
            this.sequenceNumberIsInteger);
    }
    get minIsLessThanMax() {
        if (!this.formData.minQuantity || !this.formData.maxQuantity) {
            return false;
        }
        return this.formData.minQuantity <= this.formData.maxQuantity;
    }
    get partReferenceTableItems() {
        return this.transformedPartReferences;
    }
    get sequenceNumberErrorMessage() {
        if (isNullOrUndefined(this.formData.sequence)) {
            return ['Sequence number is required'];
        }
        else if (this.isDuplicateBySequenceByParent) {
            return 'Sequence number exists.';
        }
        else if (!this.sequenceIsWithinSmallIntRange) {
            return 'Sequence number is out of range.';
        }
        else if (!this.sequenceNumberIsInteger) {
            return ['Sequence number must be integer'];
        }
        return null;
    }
    get sequenceNumberIsInteger() {
        return Number.isInteger(Number(this.formData.sequence));
    }
    get sequenceIsWithinSmallIntRange() {
        // 32,767 is the maximum whole number value a SMALLINT data type can store.
        return (this.formData?.sequence || 0) < 32767;
    }
    get sequenceNumberMessage() {
        if (this.isSequenceValid) {
            return 'Sequence accepted.';
        }
    }
    get shouldDisableCreateButton() {
        return !this.isFormValid || this.showPartReferenceSelector;
    }
    get subTypeDisplay() {
        return this.formData.subType ? AssemblyTemplateTypeDisplay.get(this.formData.subType) : DEFAULT_DASH;
    }
    isNotMoreThanMaxQuantity(minQuantity, maxQuantity) {
        if (!minQuantity || !maxQuantity) {
            return false;
        }
        return minQuantity <= maxQuantity;
    }
    async onClickOfCreateAssemblyTemplate() {
        if (!this.createAssemblyTemplateRequest) {
            return;
        }
        try {
            this.isSaving = true;
            const resp = await postAssemblyTemplate(this.createAssemblyTemplateRequest);
            if (resp) {
                this.$notification.add({
                    type: this.AlertType.SUCCESS,
                    text: 'Assembly template created.'
                });
                this.emitRefreshSelfEvent();
            }
        }
        catch (err) {
            console.log('err', err);
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
        this.isSaving = false;
    }
    async onClickOfAddPartReference() {
        // determine which selected part reference to add. if it is an edited part reference, do a put request.
        // transform the part reference and push it to transformedPartReferences.
        let transformedPartReference;
        if (this.selectedPartReference) {
            transformedPartReference = {
                ...transformPartReferenceResponses([this.selectedPartReference])[0],
                _tempId: crypto.randomUUID()
            };
        }
        if (this.selectedPartReferenceRequest) {
            transformedPartReference = {
                ...transformPartReferenceResponses([this.selectedPartReferenceRequest])[0],
                _tempId: crypto.randomUUID()
            };
        }
        if (this.selectedPartReferenceEdited) {
            const updatedPartReference = await this.updatePartReference(this.selectedPartReferenceEdited);
            if (!updatedPartReference) {
                this.$errorUtility({ err: new Error('Failed to update part reference.') });
                return;
            }
            transformedPartReference = {
                ...transformPartReferenceResponses([updatedPartReference])[0],
                _tempId: crypto.randomUUID()
            };
        }
        if (!transformedPartReference) {
            return;
        }
        this.transformedPartReferences.push(transformedPartReference);
        this.showPartReferenceSelector = false;
    }
    onPartReferenceEdited(partReference) {
        this.selectedPartReferenceEdited = partReference;
    }
    onPartReferenceSelected(partReference) {
        this.selectedPartReference = partReference;
    }
    onNewPartReferenceSelected(partReferenceRequest) {
        this.selectedPartReferenceRequest = partReferenceRequest;
    }
    onRemovalOfPartReference(partReference) {
        this.transformedPartReferences = this.transformedPartReferences.filter((item) => item._tempId !== partReference._tempId);
    }
    async updatePartReference(partReference) {
        const request = {
            attributes: partReference.attributes || {},
            asBuiltNumber: partReference.asBuiltNumber || null,
            birthDate: partReference.birthDate || null,
            calibrationNumber: partReference.calibrationNumber || null,
            contractEndingItemNumber: partReference.contractEndingItemNumber || null,
            drawingDescription: partReference.drawingDescription || null,
            drawingNumber: partReference.drawingNumber || null,
            isTool: partReference.isTool ?? false,
            itemClass: partReference.itemClass || null,
            lastCalibrationDate: partReference.lastCalibrationDate || null,
            location: partReference.location || null,
            lotNumber: partReference.lotNumber || null,
            notes: partReference.notes || null,
            serialNumber: partReference.serialNumber || null,
            shelfLifeExpirationDate: partReference.shelfLifeExpirationDate || null,
            side: partReference.side || Side.NONE,
            size: partReference.size || null,
            usageLifeExpirationDate: partReference.usageLifeExpirationDate || null
        };
        try {
            const resp = await putPartReference(partReference.id, request);
            return resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    emitRefreshParentEvent() {
        return this;
    }
    emitRefreshSelfEvent() {
        return this;
    }
    async onParentIdChange(parentAssemblyTemplateId) {
        if (!parentAssemblyTemplateId) {
            return;
        }
        const resp = await this.$http.get(`/assembly-templates/${parentAssemblyTemplateId}`);
        if (resp) {
            this.parentData = resp.data;
        }
    }
    setNextSequence(val) {
        if (!isNullOrUndefined(val)) {
            this.formData.sequence = val + 10;
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ModalAssemblyTemplateCreate.prototype, "parentAssemblyTemplateId", void 0);
__decorate([
    Emit('refresh-parent'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalAssemblyTemplateCreate.prototype, "emitRefreshParentEvent", null);
__decorate([
    Emit('refresh-self'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalAssemblyTemplateCreate.prototype, "emitRefreshSelfEvent", null);
__decorate([
    Watch('parentAssemblyTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], ModalAssemblyTemplateCreate.prototype, "onParentIdChange", null);
__decorate([
    Watch('largestSequenceNumber'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], ModalAssemblyTemplateCreate.prototype, "setNextSequence", null);
ModalAssemblyTemplateCreate = __decorate([
    Component
], ModalAssemblyTemplateCreate);
export default ModalAssemblyTemplateCreate;
