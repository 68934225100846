/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AppItemMasterDisplay.vue?vue&type=template&id=bfa4c096&scoped=true"
import script from "./AppItemMasterDisplay.ts?vue&type=script&lang=ts&external"
export * from "./AppItemMasterDisplay.ts?vue&type=script&lang=ts&external"
import style0 from "./AppItemMasterDisplay.vue?vue&type=style&index=0&id=bfa4c096&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfa4c096",
  null
  
)

export default component.exports